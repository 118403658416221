<template>
  <v-text-field
    v-model="value"
    type="number"
    :label="`${$t(label)}${(required) ? ' *' : ''}`"
    :prepend-icon="icon"
    :color="color"
    :min="options.min"
    :max="options.max"
    :step="options.step"
    :readonly="fieldReadonly"
    :rules="rules"
  >
    <v-tooltip slot="append" bottom v-if="$t(description)">
      <template v-slot:activator="{ on }">
        <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
      </template>
      <span>{{ $t(description) }}</span>
    </v-tooltip>
  </v-text-field>
</template>
<script>
import baseTextInput from '@/mixins/baseTextInput';

export default {
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: [String, Number],
      default: 'fields.number',
    },
    description: {
      type: [String, Number],
      default: 'fields.numberTooltip',
    },
    initial: {
      type: [String, Number],
      default: '',
    },
    /**
     * Options of number components
     *
     * {
     *   min: number // optional
     *   max: number // optional
     *   step: number // optional
     * }
     */
    options: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
